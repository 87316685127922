import * as Sentry from '@sentry/browser';
import amplitude from 'amplitude-js';
import Backbone from 'backbone';
import { Tooltip } from 'bootstrap';
import $ from 'jquery';
import Cookies from 'js-cookie';
import _ from 'underscore';

import { Log } from '@biteinc/common';
import { ApiHeader, ClientApiVersion, ModelType } from '@biteinc/enums';

import { KeyCode } from './enums/key_code';
import { BrowserHelper } from './helpers/browser_helper';
import { ModelHelper } from './helpers/model_helper';

/* eslint-disable no-undef */
require('bootstrap');
require('bootstrap-datepicker');
require('jquery.easing');
require('timepicker');
/* eslint-enable no-undef */

const sentrySerializedExtrasDepth = 10;

Sentry.init({
  dsn: 'https://bce1b298966f4a978d0b465963f810a7@o61624.ingest.sentry.io/6635349',
  integrations: [Sentry.browserTracingIntegration()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: window.env,
  release: process.env.BUREAU_RELEASE,
  normalizeDepth: sentrySerializedExtrasDepth + 1,
  enabled: window.env !== 'dev',
});

window.app = {};
_.extend(window.app, Backbone.Events);

Log.init((logLevel, ...messages) => {
  switch (logLevel) {
    case Log.LogLevel.DEBUG:
      if (['dev', 'test'].includes(window.env)) {
        // eslint-disable-next-line no-console
        console.log('DEBUG:', ...messages);
      }
      break;
    case Log.LogLevel.INFO:
      // eslint-disable-next-line no-console
      console.log('INFO:', ...messages);
      break;
    case Log.LogLevel.WARN:
      // eslint-disable-next-line no-console
      console.log('WARN:', ...messages);
      break;
    case Log.LogLevel.ERROR:
      // eslint-disable-next-line no-console
      console.error('ERROR:', ...messages);
      if (['dev', 'test'].includes(window.env)) {
        window._biteBeep();
      }
      break;
  }
});

app.apiPathForLocation = (path = '') => {
  return `/api/orgs/${app.org.id}/locations/${app.location.id}${path}`;
};

app.apiPathForMenu = (path = '') => {
  return `/api/menus/${app.menu.id}${path}`;
};

app.defaultError = 'Sorry, there was an error. Please try again later.';

app.render = function render() {
  app.data = window.__BUREAU_DATA__ || {};
  amplitude.getInstance().init(app.data.AMPLITUDE_API_KEY);

  app.InstallJqueryLoadingButton();
  app.ExtendBackboneSync();
  app.ExtendBackboneAjax();
  app.Install403ResponseHandler();

  RegExp.escape = (s) => {
    return (s || '').replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  };

  if (window.navigator.standalone) {
    $('body').addClass('standalone');
  }

  app.modalManager = new app.ModalManager({ el: $('#modals') });

  app.autoCompleteManager = new (Backbone.Model.extend({
    initialize() {
      $(document).click(() => {
        this.trigger('documentWasClicked');
      });
    },
  }))();

  if (app.data.sessionUser) {
    Sentry.setUser({
      id: app.data.sessionUser.id,
      username: app.data.sessionUser.username,
    });

    app.sessionUser = new app.User(app.data.sessionUser);
    app.sessionUser.set('isOrgWide', !!app.data.userIsOrgWide);
  }
  if (app.data.singleLocationGroup) {
    app.singleLocationGroup = new app.LocationGroup(app.data.singleLocationGroup);
  }
  if (app.data.menu) {
    app.menu = new app.Menu(app.data.menu);
  }
  if (app.data.appearance) {
    app.appearanceV2 = new app.Appearance(app.data.appearance);
  }
  if (app.data.locations) {
    const locations = app.data.locations.map((locationJson) => {
      return new app.Location(locationJson);
    });
    app.locationList = new app.LocationList(locations);
  }
  if (app.data.location) {
    app.location = new app.Location(app.data.location, {
      collection: app.locationList,
    });
  }
  if (app.data.sites) {
    const sites = app.data.sites.map((siteJson) => {
      return new app.Site(siteJson);
    });
    app.siteList = new app.SiteList(sites);
  }
  if (app.data.site) {
    app.site = new app.Site(app.data.site);
  }
  if (app.data.org) {
    app.org = new app.Org(app.data.org);
  }
  if (app.data.orgAppearances) {
    app.orgAppearanceList = new app.AppearanceList(app.data.orgAppearances);
  }
  if (app.data.vendors) {
    app.vendors = app.data.vendors.map((i9nJson) => {
      return new app.Vendor(i9nJson);
    });
  }
  if (app.data.integrations) {
    app.integrations = app.data.integrations.map((i9nJson) => {
      return new app.Integration(i9nJson);
    });
  }

  if (app.data.orgSettings) {
    app.orgSettings = new app.Settings(app.data.orgSettings);
  }
  if (app.data.siteSettings) {
    app.siteSettings = new app.Settings(app.data.siteSettings);
  }
  if (app.data.locationSettings) {
    app.locationSettings = new app.Settings(app.data.locationSettings);
  }
  if (app.data.allSiteSettings) {
    app.allSiteSettings = app.data.allSiteSettings.map((settingsJson) => {
      return new app.Settings(settingsJson);
    });
  }
  if (app.data.allSitesWithNames) {
    app.sitesById = _.indexBy(app.data.allSitesWithNames, '_id');
  }
  if (app.data.allLocationSettings) {
    app.allLocationSettings = app.data.allLocationSettings.map((settingsJson) => {
      return new app.Settings(settingsJson);
    });
  }
  if (app.data.allLocationsWithNames || app.data.locations) {
    const locations = app.data.allLocationsWithNames || app.data.locations;
    app.locationsById = _.indexBy(locations, '_id');
  }

  // DO NOT MAKE ANY NETWORK REQUESTS BEFORE THIS LINE
  $.ajaxSetup({
    headers: {
      [ApiHeader.ApiVersion]: `${ClientApiVersion.Supports400ErrorCode}`,
      ...(app.location && { [ApiHeader.LocationId]: app.location.id }),
      ...(app.site && { [ApiHeader.SiteId]: app.site.id }),
      ...(app.org && { [ApiHeader.OrgId]: app.org.id }),
      ...(window.__BUREAU_CSRF_TOKEN__ && {
        'x-csrf-token': window.__BUREAU_CSRF_TOKEN__,
      }),
    },
  });

  app.view = app._getViewForName(app.data.view);
  if (app.view) {
    if (app.view.requiresPageHeader) {
      const header = new app.HeaderView();
      $('body').prepend(header.render().$el);
      app.view.setPageHeader(header);
    }

    app.view.render();

    app.on(app.Event.ModelIdWasClicked, ({ wasHandled, parentView, $targetView }) => {
      if (wasHandled) {
        return;
      }

      const modelId = $targetView.attr('model-id');
      let modelType = parseInt($targetView.attr('model-type'), 10);
      if (!modelType) {
        modelType = ModelHelper.guessModelTypeFromModelId(modelId);
      }

      if (ModelType.MenuStructure === modelType) {
        app.router.navigate(`menus/${modelId}`, { trigger: true });
        return;
      }

      const { model, collection } = ModelHelper.getCollectionAndModelWithIdAndType(
        modelId,
        modelType,
      );

      if (model) {
        const DetailsViewClass = ModelHelper.getDetailViewClassForModelType(modelType, model);
        const detailsView = new DetailsViewClass({
          collection,
          model,
          isReadOnly: parentView.isReadOnly,
        });
        app.modalManager.showModalWithView(detailsView);
      } else {
        new app.AlertView().show(`Couldn't find anything with id: ${modelId}`);
      }
    });
  }

  $(document).bind('drop dragover', (e) => {
    e.preventDefault();
  });
};

app._getViewForName = function _getViewForName(viewName) {
  const $appView = $('#app-view');
  const viewOptions = { el: $appView };
  switch (viewName) {
    case 'OrgView':
      return new app.OrgView(viewOptions);
    case 'SiteView':
      return new app.SiteView(viewOptions);
    case 'LocationView':
      return new app.LocationView(viewOptions);
    case 'BiteView':
      return new app.BiteView(viewOptions);
    case 'CellarView':
      return new app.CellarView(viewOptions);
    case 'LoginView':
      return new app.LoginView(viewOptions);
    case 'RegisterUserView':
      return new app.RegisterUserView(viewOptions);
    default:
      return null;
  }
};

app.makeRequest = function makeRequest(method, url, data, onSuccess, onFailure) {
  app.makeRequestWithOptions({
    method,
    url,
    data,
    onSuccess,
    onFailure,
  });
};

app.makeRequestWithOptions = function makeRequestWithOptions(opts) {
  const options = _.extend(
    {
      method: 'GET',
      showAlertOnError: true,
    },
    opts || {},
  );

  const ajaxOptions = {
    url: options.url,
    type: options.method,
    ...(options.headers && { headers: options.headers }),
    success(response, status, xhr) {
      const cd = xhr.getResponseHeader('content-disposition') || '';
      if (cd.indexOf('attachment') === 0) {
        if (options.onSuccess) {
          options.onSuccess();
        }
        window.location = options.url;
        return;
      }

      if (options.onSuccess) {
        options.onSuccess(response.data, response);
      }
    },
    error(response) {
      app.handleJQueryErrorResponse({
        requestMethod: options.method,
        requestUrl: options.url,
        response,
        showErrorModal: options.showAlertOnError,
        errorHandler: options.onFailure,
      });
    },
  };
  if (_.contains(['POST', 'PUT'], ajaxOptions.type) && options.data) {
    ajaxOptions.data = options.data;
  }
  $.ajax(ajaxOptions);
};

app.postRequest = function postRequest(url, data, onSuccess, onFailure) {
  app.makeRequest('POST', url, data, onSuccess, onFailure);
};

app.showSavedToastAndReload = function showSavedToastAndReload(
  toastText,
  reloadPageAfter,
  afterTime,
) {
  const text = (toastText || '').trim().length ? toastText : 'Saved!';
  if (!app.$popupTab) {
    app.$popupTab = $('<div class="popup-tab"></div>');
    $('body').append(app.$popupTab);
  }
  app.$popupTab.html(`<div>\u2713 ${text}</div>`);
  app.$popupTab.toggleClass('show', true);
  setTimeout(() => {
    app.$popupTab.toggleClass('show', false);
  }, 3500);
  app.$popupTab.click(() => {
    app.$popupTab.removeClass('show');
  });

  if (reloadPageAfter) {
    setTimeout(() => {
      window.location.reload();
    }, afterTime || 1500);
  }
};

app.showSavedToast = function showSavedToast(text) {
  app.showSavedToastAndReload(text, false);
};

app.promptUserToTypeInConfirmation = function promptUserToTypeInConfirmation(
  message,
  confirmation,
) {
  const ack = window.prompt(message)?.trim().toLowerCase();
  return ack === confirmation.toLowerCase();
};

app.MobileBookmarkPromptModal = Backbone.View.extend({
  className: 'mobile-prompt-modal',

  setSuperView(superView) {
    this.superView = superView;
  },

  clearState() {},

  render() {
    const isAndroid = /Android/.test(window.navigator.userAgent) && !window.MSStream;
    const buttonName = isAndroid ? 'Menu' : 'Share';
    const shareSrcs = isAndroid
      ? 'src="https://static.bureau.getbite.com/images/share-button-prompt-android.png" ' +
        'srcset="https://static.bureau.getbite.com/images/share-button-prompt-android@2x.png 2x"'
      : 'src="https://static.bureau.getbite.com/images/share-button-prompt.png" ' +
        'srcset="https://static.bureau.getbite.com/images/share-button-prompt@2x.png 2x"';
    const homeSrcs = isAndroid
      ? 'src="https://static.bureau.getbite.com/images/home-screen-button-prompt-android.png" ' +
        'srcset="https://static.bureau.getbite.com/images/home-screen-button-prompt-android@2x.png 2x"'
      : 'src="https://static.bureau.getbite.com/images/home-screen-button-prompt.png" ' +
        'srcset="https://static.bureau.getbite.com/images/home-screen-button-prompt@2x.png 2x"';
    this.$el.html(
      '<ol>' +
        '<li>' +
        `Tap the <em>${buttonName}</em> button in the toolbar.` +
        '</li>' +
        `<img ${shareSrcs} />` +
        '<li>' +
        'Tap the <em>Add to Home Screen</em> button.' +
        '</li>' +
        `<img ${homeSrcs} />` +
        '</ol>' +
        '<button class="btn btn-secondary dismiss">Done</button>',
    );
    this.$('button.dismiss').click(() => {
      this.trigger(app.ModalView.Events.ModalViewShouldHide, this);
      Cookies.set('mobile-bookmark-prompt-dismissed', true, { expires: 30 });
    });
    this.superView.setBackgroundColor('#F7F7F7');
    this.superView.setMaxWidth('400px');
    this.superView.$header.remove();
    this.superView.footer.remove();
    return this;
  },
});

app.sortableDelay = function sortableDelay() {
  return BrowserHelper.isMobile() ? 100 : 0;
};

app.attachFormListeners = function attachFormListeners($inputField, $button, handler) {
  const listener = function listener() {
    const name = $inputField.val().trim();
    if (name.length <= 0) {
      return;
    }
    handler(name, $button, $inputField);
  };
  $inputField.keyup((e) => {
    if (e.keyCode === KeyCode.Enter) {
      listener();
    }
  });
  $button.click(() => {
    listener();
  });
};

app.attachNewFormListeners = function attachNewFormListeners($el, handler) {
  const $newPanels = $el.find('.new-panel');
  $.each($newPanels, (i, newPanel) => {
    const $newPanel = $(newPanel);
    app.attachFormListeners($newPanel.find('input'), $newPanel.find('button.create'), handler);
  });
};

app.renderTooltip = function renderTooltip(tooltipSchema) {
  const escapedText = tooltipSchema.text.replace(/"/g, '&quot;');
  const $tooltip = $(
    `<div class="field-tooltip" data-bs-toggle="tooltip" data-bs-title="${escapedText}"></div>`,
  );
  if (tooltipSchema.isWarning) {
    $tooltip.addClass('has-warning');
  }
  return $tooltip;
};

app.activateTooltips = function activateTooltips($el) {
  const tooltipTargets = $el.find('[data-bs-toggle="tooltip"]');
  tooltipTargets.each((index, element) => {
    new Tooltip(element, {
      html: !!$(element).data('html'),
    });
  });
};

// Remove vendor-related fields from a schema if location does not support
// vendors.
app.removeVendorFields = function removeVendorFields(location, origSchema) {
  const schema = app.JsonHelper.deepClone(origSchema);
  const fields = {};
  _.each(schema.fields, (field, key) => {
    if (!location.supportsMultipleVendors() && key === 'associatedVendorId') {
      return;
    }
    fields[key] = schema.fields[key];
  });
  schema.fields = fields;
  return schema;
};

app.getSettingsFromContext = function () {
  if (app.location) {
    return app.locationSettings;
  }
  if (!app.location && app.site) {
    return app.siteSettings;
  }
  return app.orgSettings;
};

app.Event = {
  ModelIdWasClicked: 'modelIdWasClicked',
};

$(() => {
  window.app.render();
});
