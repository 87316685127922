import { I9nSchemaBySystem } from '@biteinc/common';
import type { AnyModelFieldWithIntegrationOptions } from '@biteinc/common';
import { EnvironmentHelper } from '@biteinc/enums';
import type { IntegrationSystem } from '@biteinc/enums';
import { I9nHelper } from '@biteinc/helpers';

export module IntegrationHelper {
  export function getCopyableFieldNamesFromReferenceIntegration(
    system: IntegrationSystem,
  ): string[] {
    const schema = I9nSchemaBySystem[system];
    return Object.entries(schema.fields)
      .filter(([_fieldName, fieldSchema]) => canCopyFieldWithSchema(fieldSchema))
      .map(([fieldName, _fieldSchema]) => fieldName)
      .concat(
        getGenericFieldsForSystem(system).filter((fieldName) => {
          // make sure that dining options on this integration don't contain any fields that we
          // can't copy
          if (fieldName === 'diningOptions' && schema.type === 'pos' && schema.diningOptionFields) {
            return Object.values(schema.diningOptionFields).every((fieldSchema) => {
              return canCopyFieldWithSchema(fieldSchema);
            });
          }
          return true;
        }),
      );
  }

  function canCopyFieldWithSchema(
    fieldSchema: Readonly<AnyModelFieldWithIntegrationOptions>,
  ): boolean {
    // We can't set these values on creation
    if (fieldSchema.isSetAfterFirstSync || fieldSchema.isSetAfterSecondSync) {
      return false;
    }
    // This is the one field that must be filled out
    if (fieldSchema.identifiesTheLocation) {
      return false;
    }
    if (fieldSchema.isHidden || fieldSchema.isReadOnly) {
      return false;
    }
    return true;
  }

  export function getGenericFieldsForSystem(system: IntegrationSystem): string[] {
    const i9nSchema = I9nSchemaBySystem[system];
    const allowedGenericFields: string[] = ['notes', 'vendorId'];
    if (
      (i9nSchema.type === 'pos' || i9nSchema.type === 'fulfillment') &&
      i9nSchema.diningOptionFields
    ) {
      allowedGenericFields.push('diningOptions');
    }
    if (i9nSchema.type === 'pos') {
      if (i9nSchema.syncsSections) {
        allowedGenericFields.push('syncSections', 'preserveSectionItemOrder');
      }
      allowedGenericFields.push(
        'preserveModGroupModOrder',
        'preserveItemModGroupOrder',
        'modGroupModSortPriority',
        'revalidateOrderBeforeSending',
        'maxSendOrderConcurrency',
      );
    }
    if (I9nHelper.systemCanBeSynced(system)) {
      allowedGenericFields.push('disableSync');
    }
    if (i9nSchema.type === 'pos' || i9nSchema.type === 'loyalty') {
      allowedGenericFields.push('skipNonSyncOperations');
    }
    if (i9nSchema.type === 'loyalty') {
      allowedGenericFields.push('enableMultipleRewardRedemption');
    }
    if (i9nSchema.type === 'stored-value') {
      allowedGenericFields.push('useAsSecondaryPayment');
    }
    if (
      i9nSchema.type !== 'pos' &&
      i9nSchema.type !== 'kiosk-payment' &&
      i9nSchema.type !== 'ecomm-payment'
    ) {
      allowedGenericFields.push('disable');
    }
    if (
      ('supportsOrderStatusPolling' in i9nSchema || 'supportsOrderStatusWebhooks' in i9nSchema) &&
      (i9nSchema.supportsOrderStatusPolling || i9nSchema.supportsOrderStatusWebhooks)
    ) {
      allowedGenericFields.push('notifyUserOfOrderStatusUpdates');

      if (i9nSchema.supportsOrderStatusPolling && i9nSchema.supportsOrderStatusWebhooks) {
        allowedGenericFields.push('useWebhooksForOrderStatuses');
        allowedGenericFields.push('sendOrderStatusCheckup');
      }
    }
    if (
      'supportsPhoneNumberAsFallbackToBarcode' in i9nSchema &&
      i9nSchema.supportsPhoneNumberAsFallbackToBarcode
    ) {
      allowedGenericFields.push('enablePhoneNumberAsFallbackToBarcode');
    }
    if (
      'supportsBarcodeAsFallbackToPhoneNumber' in i9nSchema &&
      i9nSchema.supportsBarcodeAsFallbackToPhoneNumber
    ) {
      allowedGenericFields.push('enableBarcodeAsFallbackToPhoneNumber');
    }
    if (i9nSchema.type === 'supplementary-menu-source') {
      allowedGenericFields.push('syncPriority');
    }
    if ('syncsDiscounts' in i9nSchema && i9nSchema.syncsDiscounts) {
      allowedGenericFields.push('loyaltyDiscountId');
    }
    if (i9nSchema.canUsePinata && EnvironmentHelper.isCurrentDev()) {
      allowedGenericFields.push('pinataApiServer');
    }
    return allowedGenericFields;
  }
}
