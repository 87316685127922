import Backbone from 'backbone';
import _ from 'underscore';
import VirtualizedList from 'virtualized-list';

import { ErrorCode } from '@biteinc/common';
import { StringHelper } from '@biteinc/helpers';

import { template } from '../../template';

app.BaseListView = Backbone.View.extend({
  template: template($('#base-list-template').html()),
  className: 'base-list-view clearfix',
  _height: 0,
  suspended: true,

  initialize(options) {
    this.options = _.extend(
      {
        canCreate: !options.isReadOnly,
        canCollapse: false,
        searchEnabled: true,
        isLocalSearch: true,
        fetchOnInit: true,
        useVList: false,
        canAccessParentModel: true,
        updateUrlWithSearchQuery: false,
      },
      options,
    );
    this.isReadOnly = this.options.isReadOnly;
    this.canAccessParentModel = this.options.canAccessParentModel;

    // Only use the update event to react to multiple adds. If a remove
    // happens, then ignore it.
    this.listenTo(this.collection, 'update', function onUpdate(collection, event) {
      if (event.changes.added.length || event.changes.merged.length) {
        this._renderList();
      }
    });
    this.listenTo(this.collection, 'reset', function onReset() {
      if (!this.options.isLocalSearch && !this.suspended) {
        this.$searchSpinner.hide();
        if (this.collection.query.length) {
          this.$searchClear.show();
        } else {
          this.$searchButton.show();
        }
      }

      this._renderList();
      if (this._selectedItemId) {
        this.__processSelectedItemId();
      }
    });
    this.listenTo(this.collection, 'remove', function onRemove(model) {
      this._removeCellForModel(model);
      this._layoutPanes();
    });

    if (this.options.fetchOnInit && !this.collection.hasBeenFetched()) {
      this.collection.fetch({ reset: true });
    }

    this._cellById = {};
    this._cells = [];
    this._hasRowsToShow = false;
    this._currentRows = [];
    this._rowHeightCache = [];
  },

  destroy() {
    this.stopListening(this.collection);
  },

  suspend() {
    this.suspended = true;
    this.vList?.destroy();
    this.vList = null;
  },

  setHeight(height) {
    this._height = height;
    this._applyHeight();
  },

  _applyHeight() {
    if (this._height && this.$list) {
      let headerHeight = this.$('.card-header').outerHeight() + this.$listHeader.outerHeight();
      if (this.options.searchEnabled) {
        headerHeight += this.$('.search-field').outerHeight();
      }
      if (this.options.filterSchema) {
        headerHeight += this.$('.filter-container').outerHeight();
      }
      // Adjust by 2 pixels to account for borders
      this._listHeight = this._height - headerHeight - 2;
      if (this.options.useVList) {
        this.$list.css('height', `${this._listHeight}px`);
        this.vList?.resize(this._listHeight);
      } else {
        this.$list.css('maxHeight', `${this._listHeight}px`);
      }
    }
  },

  getTitle() {
    return this.options.title || StringHelper.toTitleCase(this.collection.type());
  },

  setTitle(title) {
    this.$('.card-header .title').html(title);
  },

  setCanCreate(canCreate) {
    if (this.options.canCreate !== canCreate) {
      this.options.canCreate = canCreate;
      this.render();
    }
  },

  getCreateFieldName() {
    return 'name';
  },

  createNew() {
    const DetailsViewClass = this.options.detailsView || app.BaseDetailsView;
    const detailsView = new DetailsViewClass({
      collection: this.collection,
      canAccessParentModel: this.canAccessParentModel,
    });
    this.showDetailsView(detailsView);
  },

  __cellWasClicked(cellView, _eventOpt) {
    this.editModel(cellView.model);
  },

  _cellDidBecomeSelected(cellView) {
    this.setSelectedModelId(cellView.model.id);
  },

  _cellDidBecomeUnselected(cellView) {
    if (this._selectedModelId === cellView.model.id) {
      this.setSelectedModelId(null);
    }
  },

  editModel(model) {
    const DetailsViewClass = this.options.detailsView || app.BaseDetailsView;
    const detailsView = new DetailsViewClass({
      model,
      collection: this.collection,
      isReadOnly: this.isReadOnly,
      canAccessParentModel: this.canAccessParentModel,
    });
    this.showDetailsView(detailsView);
  },

  __getCellViewClass(_model) {
    return this.options.cellView || app.CellView;
  },

  createCell(model) {
    const options = _.extend(
      { canAccessParentModel: this.canAccessParentModel },
      this.options.cellOptions || {},
    );
    options.model = model;
    const CellClass = this.__getCellViewClass(model);
    const cell = new CellClass(options);
    this.listenTo(cell, app.CellView.Events.CellViewWasClicked, this.__cellWasClicked);
    this.listenTo(cell, app.CellView.Events.CellViewDidBecomeSelected, this._cellDidBecomeSelected);
    this.listenTo(
      cell,
      app.CellView.Events.CellViewDidBecomeUnselected,
      this._cellDidBecomeUnselected,
    );
    return cell;
  },

  _removeCellForModel(model) {
    this._removeCellForModelId(model.id);
  },

  _removeCellForModelId(modelId) {
    const cell = this._cellById[modelId];
    if (cell) {
      this.stopListening(cell);
      cell.destroy();
      cell.remove();
      delete this._cellById[modelId];
      for (let i = 0; i < this._cells.length; i++) {
        if (this._cells[i] === cell) {
          this._cells.splice(i, 1);
          break;
        }
      }
    }
  },

  getDebugName() {
    return this.collection.type();
  },

  showDetailsView(detailsView) {
    this.showView(detailsView, true);
  },

  showView(view, isDetailsView) {
    let viewToInsert = view;
    let status = false;
    if (this.options.showDetailsView) {
      status = this.options.showDetailsView(view);
    } else if (this.options.tabView && !app.modalManager.mustShowModal()) {
      if (isDetailsView) {
        viewToInsert = new app.AnchoredModalView({ view });
      } else {
        const event = app.BaseListView.Events.UserDidClickCloseButton;
        this.listenToOnce(view, event, () => {
          this.options.tabView.setRightView(null);
        });
      }
      status = this.options.tabView.setRightView(viewToInsert, false, true);
    } else {
      if (!isDetailsView) {
        viewToInsert = new app.ModallableView({ views: [view] });
      }
      status = app.modalManager.showModalWithView(viewToInsert);
    }
    if (isDetailsView && status && view.model && !view.model.isNew()) {
      const evt = app.BaseDetailsView.Events.BaseDetailsViewDidClose;
      this.setSelectedModelId(view.model.id);
      this.listenToOnce(view, evt, () => {
        if (this._cellById[this._selectedModelId]) {
          this._cellById[this._selectedModelId].setSelected(false);
        }
        this.setSelectedModelId(null);
      });
      if (this._cellById[this._selectedModelId]) {
        this._cellById[this._selectedModelId].setSelected(true);
      }
    }
  },

  setSelectedModelId(selectedModelId) {
    this._selectedModelId = selectedModelId;
    if (
      this.options.updateUrlWithSelectedModel &&
      this.options.updateUrlWithSelectedModel(selectedModelId) &&
      !this.suspended
    ) {
      app.view.updateUrlParam(selectedModelId);
    }
  },

  // TODO: Audit the usage of this method
  hasFetchedAllData() {
    return this.collection.hasBeenFetched();
  },

  _getCurrentQuery() {
    return (this.$searchField.val() || '').trim().toLowerCase();
  },

  _filterByQuery() {
    if (!this.$searchField || !_.isString(this.$searchField.val())) {
      return;
    }

    const query = this._getCurrentQuery();
    if (this.options.isLocalSearch) {
      this._renderList();
      if (this.options.localSearchCallback) {
        this.options.localSearchCallback(query);
      }
    } else {
      this.$searchButton.toggleClass('disabled', !this.collection.isValidQuery(query));
      if (this.query !== query && !query.length) {
        this._clearSearch();
      }
    }

    if (this.options.updateUrlWithSearchQuery) {
      app.view.updateUrlParam(encodeURIComponent(query));
    }
  },

  setQuery(query) {
    this.$searchField.val(query);
  },

  _clearSearch() {
    this.$searchField.val('');
    this.$searchClear.hide();
    this.$searchButton.show().toggleClass('disabled', !this.collection.isValidQuery(''));
    this.$searchSpinner.hide();
    this.collection.performSearchByQuery('');
  },

  _submitSearch() {
    this.$searchButton.hide();
    this.$searchClear.hide();
    this.$searchSpinner.show();

    const query = this.$searchField.val().trim().toLowerCase();
    this.collection.performSearchByQuery(query);
  },

  getListSections() {
    return [
      {
        models: this.collection.models,
      },
    ];
  },

  _renderList() {
    if (this.hasFetchedAllData() && this.$list) {
      _.each(this._cellById, (cell, modelId) => {
        this._removeCellForModelId(modelId);
      });
      this._cellById = {};
      this._cells = [];

      this._generateRows();

      this._layoutPanes();

      this.$list.toggleClass('vlist', !!this.options.useVList);
      if (this.options.useVList) {
        this._renderVList();
      } else {
        this.$list.html('');
        this._renderModels();
      }
    } else {
      this._layoutPanes();
    }

    if (this.options.canCollapse) {
      this._collapseSimilarCells();
    }

    app.activateTooltips(this.$el);
    this.$('[model-id]').click((e) => {
      app.trigger(app.Event.ModelIdWasClicked, {
        parentView: this,
        $targetView: $(e.currentTarget),
      });
    });
  },

  __generateTableSectionHeader(listSection /* matchingModels */) {
    const headerContent = listSection.headerUrl
      ? `<a target="_blank" href="${listSection.headerUrl}">${listSection.header}</a>`
      : listSection.header;
    return $(`<div class="list-separator">${headerContent}</div>`);
  },

  _generateRows() {
    const query = this._getCurrentQuery();

    this._hasRowsToShow = false;
    this._currentRows = [];
    this._rowHeightCache = [];
    this.getListSections().forEach((listSection) => {
      this._hasRowsToShow = this._hasRowsToShow || listSection.models.length > 0;
      const matchingModels = listSection.models.filter((model) => {
        return !this.options.isLocalSearch || model.matchesQuery(query);
      });
      if (matchingModels.length) {
        if (listSection.header) {
          this._currentRows.push({
            $tableSectionHeader: this.__generateTableSectionHeader(listSection, matchingModels),
          });
          this._rowHeightCache.push(0);
        }

        this._currentRows.push(
          ...matchingModels.map((model) => {
            return { model };
          }),
        );
        this._rowHeightCache.push(0);
      }
    });
  },

  _renderVList() {
    const rowCount = this._currentRows.length;

    // 44px of white space + 1 for the border
    const kEstimatedRowHeight = 45;
    const listHeight = this._listHeight || 100;
    if (this.vList && rowCount) {
      this.vList.setRowCount(rowCount);
    } else if (rowCount) {
      let lastScrollTopValue = -1;
      const vList = new VirtualizedList(this.$list[0], {
        height: listHeight,
        rowCount,
        estimatedRowHeight: kEstimatedRowHeight,
        // adding overscanCount results in the framework asking for 1 row too many :(
        rowHeight: (index) => {
          return this._rowHeightCache[index] || kEstimatedRowHeight;
        },
        renderRow: (index) => {
          const { $tableSectionHeader, model } = this._currentRows[index];
          if ($tableSectionHeader) {
            return $tableSectionHeader[0];
          }

          const prevCell = this._cellById[model.id];
          if (prevCell) {
            this.stopListening(prevCell);
            prevCell.destroy();
            prevCell.remove();
          }

          const cell = this.createCell(model);
          this._cellById[model.id] = cell;
          cell.setSelected(model.id && model.id === this._selectedModelId);
          return cell.render().el;
        },
        onScroll: (scrollTop, _event) => {
          if (lastScrollTopValue !== -1) {
            const distance = lastScrollTopValue - scrollTop;
            // Sometimes on Chrome on Mac (not on Safari) when you scroll up the whole list will
            // jump and skip 1 screen height. It's very annoying when looking through logs.
            // This is a crude attempt to fix it.
            // This fix also causes a little bit of flashing but it's better than not being able to
            // effectively scroll up.
            if (distance > 200) {
              const newScrollTop = lastScrollTopValue - 10;
              vList.container.scrollTop = newScrollTop;
              scrollTop = newScrollTop;
            }
          }
          lastScrollTopValue = scrollTop;
        },
        onRowsRendered: ({ startIndex, stopIndex }) => {
          for (let i = startIndex; i <= stopIndex; i++) {
            const actualCellEl = vList.content.children[i - startIndex];

            // It's possible that actualCellEl is undefined because by the time this method gets
            // called, we might have re-rendered already. Ignore it in that case.
            if (!actualCellEl) {
              return;
            }

            const height = actualCellEl.clientHeight;
            if (height > 0) {
              this._rowHeightCache[i] = height;
            }

            const { model } = this._currentRows[i];
            if (model) {
              const cell = this._cellById[model.id];
              if (cell.el !== actualCellEl) {
                cell.setElement(actualCellEl);
                cell.attachListeners();
              }
            }
          }
        },
      });
      this.vList = vList;
    } else {
      this.vList?.destroy();
      this.vList = null;
    }
  },

  _renderModels() {
    _.each(this._currentRows, ({ $tableSectionHeader, model }) => {
      if ($tableSectionHeader) {
        this.$list.append($tableSectionHeader);
      } else {
        this.$list.append(this._renderModel(model));
      }
    });
  },

  getCellByModelId(modelId) {
    return this._cellById[modelId];
  },

  _renderModel(model /* index */) {
    const cell = this.createCell(model);
    if (model.id && model.id === this._selectedModelId) {
      cell.setSelected(true);
    }
    const prevCell = this._cellById[model.id];
    if (prevCell) {
      for (let i = 0; i < this._cells.length; i++) {
        if (this._cells[i] === prevCell) {
          this._cells.splice(i, 1);
          break;
        }
      }
    }
    this._cellById[model.id] = cell;
    this._cells.push(cell);
    return cell.render().el;
  },

  _collapseSimilarCells() {
    // TODO: Start from where we left off rather than from the beginning.
    let i;
    for (i = 0; i < this._cells.length; i++) {
      const currentCell = this._cells[i];
      let j = i + 1;
      while (j < this._cells.length && currentCell.model.isSimilar(this._cells[j].model)) {
        j++;
      }
      if (j - 1 !== i) {
        this._cells[i].collapseViews(this._cells.slice(i + 1, j));
        i = j - 1;
      }
    }
  },

  _hidePanes() {
    this.$list?.hide();
    this.$('.empty-pane').hide();
    this.$('.search-field').hide();
    this.$('.list-header').hide();
  },

  _layoutPanes() {
    if (this.hasFetchedAllData()) {
      this.$('.card>.loading-pane').hide();

      if (this._hasRowsToShow) {
        this.$list?.show();
        this.$('.empty-pane').hide();
        this.$('.search-field').show();
        this.$('.list-header').show();
      } else {
        this.$list?.hide();
        this.$('.empty-pane').show();
        if (this.options.isLocalSearch) {
          this.$('.search-field').hide();
        }
        this.$('.list-header').hide();
      }
    } else {
      this._hidePanes();
    }
  },

  setSelectedItemId(itemId) {
    this._selectedItemId = itemId;

    this.__processSelectedItemId();
  },

  __processSelectedItemId() {
    if (this.suspended || !this.hasFetchedAllData() || !this._selectedItemId) {
      return;
    }

    const model = this.collection.get(this._selectedItemId);
    if (model) {
      this._selectedItemId = null;
      this.__navigateToModel(model);
    } else {
      this.__fetchSelectedItem(this._selectedItemId);
    }
  },

  __fetchSelectedItem(selectedItemId) {
    this.listenToOnce(this.collection, 'update', () => {
      this.__processSelectedItemId();
    });
    const modelDisplayName = this.collection.getSchema().displayName;
    this.collection.fetchModelWithId(selectedItemId, (err) => {
      if (err) {
        let message = `There was an error loading the selected ${modelDisplayName}.\nPlease try again later.`;
        if (
          ErrorCode.UrlParamModelNotFound === err.code ||
          ErrorCode.ApiNoRightToResource === err.code
        ) {
          message = `The selected ${modelDisplayName} was not found.`;
          app.view.updateUrlParam(null);
        }
        new app.AlertView().show(message);
      }
    });
  },

  __navigateToModel(model) {
    this.editModel(model);
  },

  scrollToItemWithId(itemId, flash) {
    if (this.vList) {
      const index = _.findIndex(this._currentRows, ({ model }) => {
        return model && model.id === itemId;
      });
      if (index >= 0) {
        this.vList.scrollToIndex(index, 'start');
        if (flash) {
          setTimeout(() => {
            const { model } = this._currentRows[index];
            const cell = this._cellById[model.id];
            cell.flash();
          }, 400);
        }
      }
    } else {
      const cell = this._cellById[itemId];
      if (cell) {
        this.$list.animate(
          {
            scrollTop: cell.$el.offset().top - this.$list.offset().top,
          },
          400,
          'easeInOutExpo',
          () => {
            if (flash) {
              cell.flash();
            }
          },
        );
      }
    }
  },

  render() {
    const self = this;
    this.suspended = false;

    const modelType = this.collection.getSchema().displayName;
    const options = _.extend(
      {
        title: this.getTitle(),
        subtitle: null,
        subtitleWarning: null,
        modelType,
        searchPlaceholder: `search ${StringHelper.pluralize(modelType)}`,
        placeholder: `enter new ${modelType} name`,
        emptyText: `no ${StringHelper.pluralize(modelType)} yet`,
        filterSchema: null,
        filterSchemaButton: false,
      },
      this.options,
    );

    // Maybe fix this madness with missing isLocalSearch variable
    options.isLocalSearch = options.isLocalSearch || false;

    this.$el.html(this.template(options));
    this.vList?.destroy();
    this.vList = null;

    if (this.options.canBeClosed) {
      const $closeButton = $(
        '<button type="button" class="close" aria-label="Close">' +
          '<span aria-hidden="true">&times;</span>' +
          '</button>',
      );
      this.$('.card-header .right-button-container').append($closeButton);
      $closeButton.click(() => {
        self.trigger(app.BaseListView.Events.UserDidClickCloseButton);
        return false;
      });
    }

    if (this.options.filterSchema) {
      const filterFieldGroupView = new app.FieldGroupView({
        schema: this.options.filterSchema,
      });
      this.$('.filter-container').prepend(filterFieldGroupView.render().$el);

      this.collection.filterFieldGroupView = filterFieldGroupView;
      filterFieldGroupView.setupListViewFilter(this);
      this.listenTo(
        filterFieldGroupView,
        app.FieldGroupView.Events.FieldGroupDidChangeValue,
        () => {
          this.$searchButton.toggleClass(
            'disabled',
            !this.collection.isValidQuery(this._getCurrentQuery()),
          );
        },
      );

      if (this.options.filterSchemaButton) {
        const $filterButton = this.$('.filter-container button.filter');
        $filterButton.click(() => {
          this.$('.card>.loading-pane').show();
          this._hidePanes();

          const reqId = $filterButton.initLoadingButton(
            $filterButton.html(),
            'Filtering...',
            'Filtered!',
          );
          this.collection.fetchWithFilter(filterFieldGroupView.getValue(), (err) => {
            if (err) {
              $filterButton.loadingDidFinishWithError(reqId);
            } else {
              $filterButton.loadingDidFinishSuccessfully(reqId);
            }
          });
        });
      }
    }

    this.$searchField = this.$('.search-field input');
    this.$searchHint = this.$('.search-field .search-hint');
    this.$searchButton = this.$('.search-field button.search').addClass('disabled');
    this.$searchButton.click(() => {
      self._submitSearch();
      return false;
    });
    this.$searchClear = this.$('.search-field button.clear').hide();
    this.$searchClear.click(() => {
      self._clearSearch();
      return false;
    });
    this.$searchSpinner = this.$('.search-field img.spinner').hide();
    this.$listHeader = this.$('.list-header');
    this.$list = this.$('.list-container');

    this.$('.card-header button.create').click(this.createNew.bind(this));
    this.$searchField.on('keyup', this._filterByQuery.bind(this));
    this.$searchField.on('search', this._filterByQuery.bind(this));

    this._renderList();
    this.delegateEvents();

    setTimeout(() => {
      this._applyHeight();
    }, 1);

    // Fetch the collection on first render if we don't fetch on init
    if (!this.collection.hasBeenFetched() && !this.options.fetchOnInit) {
      this.collection.fetch({ reset: true });
    }

    return this;
  },
});

app.BaseListView.Events = {
  UserDidClickCloseButton: 'UserDidClickCloseButton',
};
