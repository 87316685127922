import Cookies from 'js-cookie';
import _ from 'underscore';

import { ModelType, OrderChannelHelper } from '@biteinc/enums';
import {
  appearanceSchema,
  locationSchema,
  menuAppearanceSchema,
  settingsSchema,
} from '@biteinc/schemas';

import { KeyCode } from '../enums/key_code';
import { BrowserHelper } from '../helpers/browser_helper';

app.LocationView = app.TabbedView.extend({
  requiresPageHeader: true,
  context: 'location',

  initialize() {
    const isBite = app.sessionUser.isBite();

    ['change:multiVendorSupport', 'change:flashEnabled', 'change:menuReferenceLocationId'].forEach(
      (eventName) => {
        this.listenTo(app.location, eventName, () => {
          setTimeout(() => {
            window.location.reload();
          }, 10);
        });
      },
    );
    ['change:kioskOrderingMode'].forEach((eventName) => {
      this.listenTo(app.locationSettings, eventName, () => {
        setTimeout(() => {
          window.location.reload();
        }, 10);
      });
    });

    app.TabbedView.prototype.initialize.apply(this, arguments);

    const posI9nSchema = app.location.getPosI9nSchema();

    const canAccessAllVendors = app.sessionUser.canAccessAllVendors();
    const canAccessLibrary = app.sessionUser.canViewMenuLibrary();
    const canAccessMenuCovers = app.sessionUser.canManageMenuCovers();
    const canAccessCoupons =
      app.sessionUser.canManageDiscounts() &&
      posI9nSchema &&
      posI9nSchema.supportsApplyingDiscounts;
    const canManageMenu = app.sessionUser.canManageMenu();
    const canAccessPosSync = app.sessionUser.canAccessPosSync() && posI9nSchema;
    const canAccessChannelSettings = app.sessionUser.canManageChannelSettings();
    const canAccessCommonSettings = app.sessionUser.canAccessSomeCommonSettings();
    const canAccessAppearance = app.sessionUser.canEditAppearance();
    const canAccessFlashSettings =
      app.sessionUser.canManageFlashSettings() && app.location.get('flashEnabled');
    const canAccessIntegrations = app.sessionUser.canManageSomeI9ns();
    const canAccessBiteLift = app.sessionUser.canManageBiteLift();
    const canViewVendors = app.sessionUser.canViewVendors();
    const canAccessKiosks =
      app.sessionUser.canViewKiosks() &&
      OrderChannelHelper.isKiosk(app.location.get('orderChannel'));
    const canAccessOrders = app.sessionUser.canViewOrders();
    const canAccessUsers = app.sessionUser.canManageUsers();
    const canAccessSystemLogs = app.sessionUser.canViewSystemLogs();
    const canAccessReporting = app.sessionUser.canGenerateReports();

    app.externalAuthStrategy = new app.ExternalAuthStrategy();

    if (canViewVendors || canAccessReporting) {
      app.vendorList = new app.VendorList(app.vendors || []);
    }

    if (canViewVendors) {
      // Integration list depends on vendor list
      app.integrationList = new app.IntegrationList(app.integrations || []);
    }
    if (app.location.get('menuReferenceLocationId') && app.sessionUser.isBite()) {
      app.referenceIntegrationList = new app.IntegrationList([], {
        menuReferenceLocationId: app.location.get('menuReferenceLocationId'),
      });
    }

    if (canAccessLibrary || canAccessAppearance) {
      app.badgeList = new app.BadgeList();
    }

    if (canAccessLibrary) {
      app.menuTimingList = new app.MenuTimingList();

      if (!canManageMenu) {
        app.menuTimingList.fetch({ reset: true });
      }
    }

    if (canAccessAllVendors && (canAccessLibrary || canAccessMenuCovers)) {
      app.menuCoverList = new app.MenuCoverList();
    }

    if (canAccessLibrary || canAccessCoupons) {
      app.modList = new app.ModList();

      if (!canAccessLibrary) {
        app.modList.fetch({ reset: true });
      }
    }

    if (canAccessLibrary) {
      app.modGroupList = new app.ModGroupList([], {
        itemList: app.modList,
      });
    }

    if (canAccessLibrary || canAccessCoupons || canViewVendors) {
      app.menuItemList = new app.MenuItemList([], {
        badgeList: app.badgeList,
        menuTimingList: app.menuTimingList,
        modGroupList: app.modGroupList,
      });

      if (!canAccessLibrary) {
        app.menuItemList.fetch({ reset: true });
      }
    }

    if (canAccessLibrary || canAccessChannelSettings) {
      // Needed by order lead times
      app.menuSectionList = new app.MenuSectionList([], {
        itemList: app.menuItemList,
      });

      if (!canAccessLibrary) {
        app.menuSectionList.fetch({ reset: true });
      }
    }

    if (canAccessKiosks) {
      app.kioskStatusList = new app.KioskStatusList();
    }

    if (canAccessKiosks || canAccessOrders) {
      app.kioskList = new app.KioskList();

      if (!canAccessKiosks) {
        // If the kiosks tab does not exist, then fetch the kiosks manually
        app.kioskList.fetch({ reset: true });
      }
    }

    if (canAccessUsers) {
      app.userRoleList = new app.UserRoleList();
    }

    if (canAccessLibrary && canAccessAllVendors) {
      app.menuStructureList = new app.MenuStructureList([], {
        menuSectionList: app.menuSectionList,
      });

      // Menu Tab
      let msSubtitle = null;
      if (app.location.isInDifferentTimezone()) {
        msSubtitle = `All times are in ${app.location.getTimezoneAbbr()}`;
      }
      const menuStructureListView = new app.MenuStructureListView({
        collection: app.menuStructureList,
        searchEnabled: false,
        subtitle: msSubtitle,
      });
      const menuStructureTab = new app.TabView();
      menuStructureTab.supportsElementRouting = true;
      menuStructureTab.setLeftView(menuStructureListView);
      const kioskPreviewView = new app.KioskPreviewView({
        previewName: 'Currently Shown',
      });
      menuStructureTab.setRightView(kioskPreviewView);
      const updateFulfillmentMethodWarnings = () => {
        const fulfillmentMethods = _.map(app.menuStructureList.models, (menuStructure) => {
          return menuStructure.get('fulfillmentMethods');
        });
        const uniqueFulfillmentMethods = _.uniq(_.flatten(fulfillmentMethods));
        kioskPreviewView.setFulfillmentMethods(uniqueFulfillmentMethods);
      };
      this.listenTo(app.menuStructureList, 'change', updateFulfillmentMethodWarnings);
      this.listenTo(app.menuStructureList, 'reset', updateFulfillmentMethodWarnings);

      this.addTab(menuStructureTab, 'Menus', 'menus');

      this.addSpacer();
    }

    if (canAccessLibrary) {
      this.addSeparatorWithTitle('Menu Library');

      if (canAccessLibrary) {
        // If the location does not have a POS, then we manage the entire menu
        // If the location is multi-vendor, then we manage the menu for nonintegrated vendors
        const canCreateMenuModels =
          canManageMenu && (app.vendorList.isSomeVendorNonintegrated() || !posI9nSchema);
        // Menu Sections/Items/Modifiers Sets/Modifier Tab
        this.addTab(
          new app.MenuItemArrayTabView({
            collection: app.menuSectionList,
            canCreate:
              !app.location.syncsModelsWithType(ModelType.MenuSection) || canCreateMenuModels,
            isReadOnly: !canManageMenu,
            // A user can only access structures if they can access all vendors
            canAccessParentModel: canAccessAllVendors,
          }),
        );
        this.addTab(
          new app.MenuItemTabView({
            collection: app.menuItemList,
            canCreate: canCreateMenuModels,
            isReadOnly: !canManageMenu,
          }),
        );
        if (app.location.supportsCompoundMenuItems()) {
          this.addTab(
            new app.CompoundMenuItemTabView({
              // use same menu item list, override schema and filter methods in views
              collection: app.menuItemList,
              canCreate: canManageMenu,
              isReadOnly: !canManageMenu,
            }),
            // Add a line break so that the text is more balanced
            'Compound<br />Menu Items',
            'compound-menu-items',
          );
        }
        this.addTab(
          new app.MenuItemArrayTabView({
            collection: app.modGroupList,
            canCreate: canCreateMenuModels,
            isReadOnly: !canManageMenu,
          }),
        );
        this.addTab(
          new app.MenuItemTabView({
            collection: app.modList,
            canCreate: canCreateMenuModels,
            isReadOnly: !canManageMenu,
          }),
        );
      }

      if (canManageMenu && canAccessAllVendors) {
        // Day Parts Tab
        const subtitle = app.location.isInDifferentTimezone()
          ? `All times are in ${app.location.getTimezoneAbbr()}`
          : null;
        const menuTimingListView = new app.BaseListView({
          collection: app.menuTimingList,
          searchEnabled: false,
          subtitle,
        });

        app.openHoursOverrideList = new app.OpenHoursOverrideList();
        app.openHoursTimetableList = new app.OpenHoursTimetableList();

        const openHoursTimetableListView = new app.BaseListView({
          collection: app.openHoursTimetableList,
          searchEnabled: false,
          canCreate: false,
          subtitle,
          isReadOnly: app.location.syncsModelsWithType(ModelType.OpenHoursTimetable),
          fetchOnInit: false,
        });
        const openHoursOverrideListView = new app.BaseListView({
          collection: app.openHoursOverrideList,
          searchEnabled: false,
          canCreate: !app.location.syncsModelsWithType(ModelType.OpenHoursOverride),
          isReadOnly: app.location.syncsModelsWithType(ModelType.OpenHoursOverride),
          subtitle,
          fetchOnInit: false,
        });
        const openHoursView = new app.MultiView({
          views: [openHoursTimetableListView, openHoursOverrideListView],
        });
        const dayPartTab = new app.TabView();
        dayPartTab.setLeftView(menuTimingListView);
        dayPartTab.setRightView(openHoursView);
        // Add a line break so that the text is more balanced
        this.addTab(dayPartTab, 'Day Parts &<br />Open Hours', 'day-parts');
      }

      if (app.location.usesTaxProfiles()) {
        const userCanUpsertTaxProfiles = canManageMenu && canAccessAllVendors;

        // Tax Profile Tab
        app.taxProfileList = new app.TaxProfileList();
        const taxProfileListView = new app.BaseListView({
          collection: app.taxProfileList,
          searchEnabled: false,
          isReadOnly: !userCanUpsertTaxProfiles,
          fetchOnInit: false,
          canCreate:
            userCanUpsertTaxProfiles &&
            (!posI9nSchema ||
              posI9nSchema.usesManualTaxProfiles ||
              app.vendorList.isSomeVendorNonintegrated()),
        });
        const taxProfileTab = new app.TabView();
        taxProfileTab.setLeftView(taxProfileListView);
        this.addTab(taxProfileTab, 'Tax Profiles', 'tax-profiles');
      }

      this.addSpacer();
    }

    const canAccessActivityLog = app.sessionUser.canViewActivities();

    if (
      canAccessKiosks ||
      canAccessOrders ||
      canAccessPosSync ||
      canAccessReporting ||
      canAccessActivityLog ||
      canAccessSystemLogs
    ) {
      this.addSeparatorWithTitle('Ops');

      if (canAccessOrders) {
        // ORDERS TAB
        app.orderList = new app.OrderList();
        const orderTab = new app.TabView();
        orderTab.supportsElementRouting = true;
        const orderListView = new app.OrderListView({
          collection: app.orderList,
          tabView: orderTab,
          fetchOnInit: false,
        });
        orderTab.setLeftView(orderListView);
        this.addTab(orderTab, 'Orders', 'orders');
      }

      if (canAccessKiosks) {
        // KIOSKS TAB
        app.expoPrinterList = new app.ExpoPrinterList();

        const kioskTab = new app.KioskTabView();
        this.addTab(kioskTab, 'Kiosks', 'kiosks');
      }

      if (canAccessPosSync) {
        // POS Sync TAB
        const quicksandTab = new app.QuicksandTabView();
        this.addTab(quicksandTab, 'POS Sync', 'pos-sync');
      }

      if (canAccessReporting) {
        // REPORTING TAB
        const oneTimeReportView = new app.OneTimeReportView({
          model: new app.OneTimeReport(),
          schema: app.OneTimeReport.Schema(),
          title: 'Run a One-Time Report',
          saveButtonTitles: ['Run', 'Running', 'Done!'],
        });

        app.reportScheduleList = new app.ReportScheduleList();
        const reportScheduleListView = new app.BaseListView({
          collection: app.reportScheduleList,
          detailsView: app.ReportScheduleDetailsView,
          searchEnabled: false,
          fetchOnInit: false,
        });
        const reportingTab = new app.TabView();
        reportingTab.setLeftView(oneTimeReportView, true);
        reportingTab.setRightView(reportScheduleListView);
        this.addTab(reportingTab, 'Reporting', 'reporting');
      }

      if (canAccessActivityLog) {
        // ACTIVITY LOGS TAB
        const activityTab = new app.TabView();
        app.activityList = new app.MaitredActivityList();
        const activityListView = new app.MaitredActivityListView({
          collection: app.activityList,
        });
        activityTab.setLeftView(activityListView);
        this.addTab(activityTab, 'Activity Log', 'activity-log');
      }

      if (isBite) {
        if (app.location.hasIdentifiableKioskGuests()) {
          // GUESTS TAB
          app.guestList = new app.GuestList();
          const guestListView = new app.BaseListView({
            collection: app.guestList,
            searchEnabled: false,
            canCreate: false,
            detailsView: app.GuestView,
            fetchOnInit: false,
          });
          const guestTab = new app.TabView();
          guestTab.setLeftView(guestListView);
          this.addTab(guestTab, `${app.HtmlHelper.biteRightIcon} Guests`, 'guests');
        }
      }

      if (canAccessSystemLogs) {
        // SYSTEM LOGS TAB
        app.biteLogList = new app.BiteLogList();
        const biteLogTab = new app.TabView();
        biteLogTab.supportsElementRouting = true;
        const biteLogListView = new app.BiteLogListView({
          collection: app.biteLogList,
          tabView: biteLogTab,
        });
        biteLogTab.setLeftView(biteLogListView);
        this.addTab(biteLogTab, `${app.HtmlHelper.resellerRightIcon} System Logs`, 'system-logs');
      }
    }

    const canAccessVendors = app.location.supportsMultipleVendors() && canViewVendors;
    const canAccessBiteSettings = isBite;
    const canAccessKioskSettings =
      app.sessionUser.canManageChannelSettings() &&
      OrderChannelHelper.isKiosk(app.location.get('orderChannel'));

    const canAccessChangesets = app.sessionUser.canManageChangesets();

    if (
      canAccessMenuCovers ||
      canAccessCoupons ||
      canAccessVendors ||
      canAccessUsers ||
      canAccessAppearance ||
      canAccessIntegrations ||
      canAccessChannelSettings ||
      canAccessBiteSettings ||
      canAccessFlashSettings ||
      canAccessKioskSettings ||
      canAccessBiteLift ||
      canAccessCommonSettings ||
      canAccessChannelSettings ||
      canAccessChangesets
    ) {
      this.addSpacer();
      this.addSeparatorWithTitle('Configuration');

      if (canAccessMenuCovers) {
        // Menu Covers Tab
        app.menuCoverPromoList = new app.MenuCoverPromoList();
        const menuCoverTabView = new app.MenuCoverTabView({ model: app.menu });
        this.addTab(menuCoverTabView, 'Menu Covers', 'menu-cover');
      }

      if (canAccessVendors) {
        // Vendors Tab
        const vendorListView = new app.BaseListView({
          collection: app.vendorList,
          detailsView: app.VendorDetailsView,
          searchEnabled: false,
          isReadOnly: !(canAccessAllVendors && canManageMenu),
        });
        const vendorTab = new app.TabView();
        vendorTab.setLeftView(vendorListView);
        this.addTab(vendorTab, 'Vendors', 'vendors');
      } else {
        app.vendorList.fetch({ reset: true });
      }

      if (canAccessBiteLift) {
        // RECOMMENDATION SETTINGS TAB
        const recommendationsTab = new app.TabView();
        app.recommendationVariationList = new app.RecommendationVariationList();
        const recommendationVariationsView = new app.BaseListView({
          collection: app.recommendationVariationList,
          detailsView: app.RecommendationVariationDetailsView,
          cellView: app.RecommendationVariationCellView,
          fetchOnInit: false,
        });

        app.recommendationRuleList = new app.RecommendationRuleList();
        const recommendationRulesView = new app.BaseListView({
          collection: app.recommendationRuleList,
          detailsView: app.RecommendationRuleDetailsView,
          cellView: app.RecommendationRuleCellView,
          fetchOnInit: false,
        });

        const tabId = 'recommendation-settings';
        const subSchema = this.buildSubSchemaFromTabId(locationSchema, tabId);

        const recommendationLocationSettingsSubSchema = subSchema;
        const recommendationLocationSettingsView = new app.LocationDetailsView({
          model: app.location,
          schema: recommendationLocationSettingsSubSchema,
        });

        const flourishesView = new app.MultiView({
          views: [recommendationVariationsView, recommendationRulesView],
        });

        recommendationsTab.supportsViewportMode = false;
        recommendationsTab.setLeftView(flourishesView, false);
        recommendationsTab.setRightView(recommendationLocationSettingsView, true);
        this.addTab(recommendationsTab, 'Bite Lift', tabId);
      }

      if (canAccessCoupons && app.location.hasCouponProvider()) {
        // Coupons Tab
        app.couponList = new app.CouponList();
        const couponListView = new app.CouponListView({
          collection: app.couponList,
          detailsView: app.CouponDetailsView,
          fetchOnInit: false,
        });
        const couponTab = new app.TabView();
        couponTab.setLeftView(couponListView);

        app.appliedBiteCouponLogList = new app.AppliedBiteCouponLogList();
        const biteLogListView = new app.BiteLogListView({
          collection: app.appliedBiteCouponLogList,
          searchEnabled: false,
          filterSchemaButton: false,
          filterSchema: null,
        });
        couponTab.setRightView(biteLogListView);

        this.addTab(couponTab, 'Coupons', 'coupons');
      }

      if (canAccessChannelSettings) {
        // ORDER LEAD TIME TAB
        this._addSubSchemaTab('Order Lead Times', 'order-lead-time');
      }

      if (canAccessFlashSettings) {
        // FLASH SETTINGS TAB
        this._addSubSchemaTab('Flash Settings', 'flash-settings');
      }

      if (canAccessKioskSettings) {
        // KIOSK SETTINGS TAB
        this._addSubSchemaTab('Kiosk Settings', 'kiosk-settings');
      }

      if (canAccessCommonSettings) {
        // COMMON SETTINGS TAB
        const commonSettingsTab = this._addSubSchemaTab('Common Settings', 'common-settings');

        if (app.sessionUser.canMagicCopyLocation()) {
          commonSettingsTab.setRightView(new app.LocationToolsView());
        }
      }

      if (canAccessChannelSettings) {
        // SMS SETTINGS TAB
        this._addSubSchemaTab('SMS Settings', 'sms-settings');
        // RECEIPT SETTINGS TAB
        this._addSubSchemaTab('Receipt Settings', 'receipt-settings');
      }

      if (canAccessAppearance) {
        // APPEARANCE TAB
        const badgeListView = new app.BaseListView({
          collection: app.badgeList,
          fetchOnInit: false,
        });
        app.dictionaryVolumeList = new app.DictionaryVolumeList();
        app.dictionaryWordList = new app.DictionaryWordList();
        const dictionaryWordListView = new app.DictionaryWordListView({
          collection: app.dictionaryWordList,
          fetchOnInit: false,
        });
        const menuAppearanceView = new app.MenuAppearanceDetailsView({
          model: app.menu,
          schema: menuAppearanceSchema,
          subProperty: 'appearance',
        });
        const appearanceTab = new app.TabView();
        const flourishesView = new app.MultiView({
          views: [badgeListView, dictionaryWordListView],
        });
        appearanceTab.supportsViewportMode = false;
        appearanceTab.setLeftView(menuAppearanceView, true);
        appearanceTab.setRightView(flourishesView);
        this.addTab(appearanceTab, 'Appearance', 'menu-appearance');

        app.customCssGcnBuildList = new app.CustomCssGcnBuildList();
        app.customCssVitrineBuildList = new app.CustomCssVitrineBuildList();

        const appearanceV2View = new app.AppearanceV2DetailsView({
          model: app.appearanceV2,
          schema: appearanceSchema,
        });
        const appearanceV2Tab = new app.TabView();
        appearanceV2Tab.setLeftView(appearanceV2View, true);
        this.addTab(appearanceV2Tab, 'Appearance v2', 'appearance-v2');
      }

      if (canAccessUsers) {
        // Staff Tab
        app.userList = new app.UserList();
        const userListView = new app.UserListView({
          collection: app.userList,
          detailsView: app.UserDetailsView,
          fetchOnInit: false,
        });

        const bohTab = new app.TabView();
        bohTab.setLeftView(userListView);
        bohTab.addKeyboardListener(KeyCode.n, () => {
          userListView.createNew();
        });
        this.addTab(bohTab, 'Users', 'back-office');
      }

      if (canAccessIntegrations) {
        // INTEGRATIONS TAB
        const integrationsTab = new app.IntegrationsTabView();
        this.addTab(integrationsTab, 'Integrations', 'integrations');
      }

      if (canAccessChangesets) {
        // Changesets Tab
        app.changesetList = new app.ChangesetList();
        const changesetsView = new app.PaginatedListView({
          collection: app.changesetList,
          detailsView: app.ChangesetDetailsView,
          searchEnabled: false,
          fetchOnInit: false,
          canCreate: true,
          cellOptions: {
            clickable: true,
          },
        });
        const changesetsTab = new app.TabView();
        changesetsTab.setLeftView(changesetsView);
        this.addTab(changesetsTab, 'Changesets', 'changesets');
      }

      if (canAccessBiteSettings) {
        // LOCATION SETTINGS TAB
        const settingsTab = this._addSubSchemaTab(
          `${app.HtmlHelper.biteRightIcon} Bite Settings`,
          'bite-settings',
        );
        settingsTab.setRightView(new app.LocationToolsView());
      }

      if (
        canAccessBiteSettings ||
        canAccessCommonSettings ||
        canAccessFlashSettings ||
        canAccessKioskSettings ||
        canAccessAppearance ||
        canAccessBiteLift
      ) {
        this.addSpacer();
        this.addSeparatorWithTitle('Configuration V2');
        if (canAccessBiteSettings) {
          this.addSettingsTab(
            app.locationSettings,
            settingsSchema,
            'Bite Settings',
            'bite-settings-v2',
            app.SettingsDetailsView,
          );
        }
        if (canAccessCommonSettings) {
          this.addSettingsTab(
            app.locationSettings,
            settingsSchema,
            'Common Settings',
            'common-settings-v2',
            app.SettingsDetailsView,
          );
        }
        if (canAccessFlashSettings) {
          // FLASH SETTINGS TAB
          this.addSettingsTab(
            app.locationSettings,
            settingsSchema,
            'Flash Settings',
            'flash-settings-v2',
            app.SettingsDetailsView,
          );
        }

        if (canAccessKioskSettings) {
          // KIOSK SETTINGS TAB
          this.addSettingsTab(
            app.locationSettings,
            settingsSchema,
            'Kiosk Settings',
            'kiosk-settings-v2',
            app.SettingsDetailsView,
          );
        }
        if (canAccessAppearance) {
          this.addSettingsTab(
            app.locationSettings,
            settingsSchema,
            'Menu Appearance Settings',
            'menu-appearance-settings-v2',
            app.SettingsDetailsView,
          );
        }
        if (canAccessBiteLift) {
          this.addSettingsTab(
            app.locationSettings,
            settingsSchema,
            'Recommendations Settings',
            'recommendation-settings-v2',
            app.SettingsDetailsView,
          );
        }
      }
    }

    this.addBottomLinks(true /* help */, true /* edit yourself */);

    // Mobile Bookmark
    const $bookmarkPrompt = $('#mobile-bookmark-prompt');
    $bookmarkPrompt.dismiss = function dismiss(callback) {
      this.stop().animate(
        {
          bottom: `${this.outerHeight() * -1}px`,
        },
        400,
        'easeInOutExpo',
        () => {
          this.hide();
          if (callback) {
            callback();
          }
        },
      );
    };
    $bookmarkPrompt.find('button.decline').click(() => {
      $bookmarkPrompt.dismiss();
      Cookies.set('mobile-bookmark-prompt-dismissed', true, { expires: 30 });
    });
    $bookmarkPrompt.find('button.show').click(() => {
      $bookmarkPrompt.dismiss(() => {
        const modal = new app.MobileBookmarkPromptModal();
        app.modalManager.showModalWithView(modal);
      });
    });

    const hasDismissed = Cookies.get('mobile-bookmark-prompt-dismissed');
    if (
      BrowserHelper.isMobile() &&
      !window.navigator.standalone &&
      !hasDismissed &&
      !app.sessionUser.isBite()
    ) {
      $bookmarkPrompt.show();
    }
  },

  setPageHeader(header) {
    app.TabbedView.prototype.setPageHeader.apply(this, arguments);

    if (app.sessionUser.isBite() || app.sessionUser.getOrgMemberships().length === 1) {
      header.setMainTitle(app.org.get('name'), app.org.get('name'), app.org.bureauUrl());
    } else {
      header.addOrgDropdown();
    }
    header.addSiteDropdown(app.siteList.models, app.location.get('name'));

    const siteLocations = app.locationList.getLocationsWithSiteId(app.site.id);
    header.addLocationDropdown(siteLocations, app.location);
  },

  _addSubSchemaTab(tabName, tabId) {
    return this.addSettingsTab(
      app.location,
      locationSchema,
      tabName,
      tabId,
      app.LocationDetailsView,
    );
  },
});
