import { KioskHelper } from '@biteinc/business-rules';
import {
  allEnumValues,
  ApiHeader,
  KioskDeviceType,
  KioskDeviceTypeHelper,
  KioskKnownIssue,
  KioskPaymentTerminalModel,
  KioskPaymentTerminalModelHelper,
  KioskPeripheralType,
  KioskPeripheralTypeHelper,
  KioskPusherState,
  ModelType,
  PrinterType,
  PrinterTypeHelper,
} from '@biteinc/enums';
import { Time } from '@biteinc/helpers';
import { kioskSchema } from '@biteinc/schemas';

import { TimeHelper } from '../helpers/time_helper';

app.Kiosk = app.AbstractModel.extend({
  ModelName: 'kiosk',
  Schema: kioskSchema,
  Type: ModelType.Kiosk,

  _didFetchMdmStatus: false,
  _mdmStatus: null,

  defaults() {
    return {
      paymentTerminalModel: KioskPaymentTerminalModel.None,
    };
  },

  initialize(/* data, options */) {
    app.AbstractModel.prototype.initialize.apply(this, arguments);

    if (this.has('status')) {
      this.status = new app.KioskStatus(this.get('status'));
    }
  },

  displayName() {
    return this.get('name') || this.get('serialNumber');
  },

  destroy(opts, ...args) {
    if (!app.location || app.location.isLive()) {
      const confirmed = app.promptUserToTypeInConfirmation(
        `Please type in "${this.displayName()}" to confirm that you want to delete this kiosk from this live location.`,
        this.displayName(),
      );
      if (!confirmed) {
        opts.error();
        return;
      }
    }
    app.AbstractModel.prototype.destroy.apply(this, [opts, ...args]);
  },

  hasKnownIssue(knownIssue) {
    return !!(this.get('knownIssues') || {})[knownIssue];
  },

  getMdmUrl(callback) {
    app.makeRequestWithOptions({
      method: 'GET',
      url: `${this.url()}/mdm-url`,
      // Specify the orgId and locationId headers because this endpoint might be called from the
      // bite-level page where no org is defined.
      headers: {
        [ApiHeader.OrgId]: this.get('orgId'),
        [ApiHeader.LocationId]: this.get('locationId'),
      },
      onSuccess: (data) => {
        callback(null, data.url);
      },
    });
  },

  getMdmRemoteAccessUrl(callback) {
    app.makeRequestWithOptions({
      method: 'GET',
      url: `${this.url()}/mdm-remote-access-url`,
      // Specify the orgId and locationId headers because this endpoint might be called from the
      // bite-level page where no org is defined.
      headers: {
        [ApiHeader.OrgId]: this.get('orgId'),
        [ApiHeader.LocationId]: this.get('locationId'),
      },
      onSuccess: (data) => {
        callback(null, data.url);
      },
    });
  },

  getMdmStatusSummary() {
    let mdmStatusSummary = '';
    if (!this._canFetchMdmStatus()) {
      // TODO: figure out how to fetch status from EloView/Esper/SignageOS
      return mdmStatusSummary;
    }

    if (!this._mdmStatus) {
      return `Error fetching mdm status`;
    }

    if (this._mdmStatus.lastSeenAt) {
      const lastSeenAtDate = new Date(this._mdmStatus.lastSeenAt);
      const lastSeenAtStr = TimeHelper.format(lastSeenAtDate);
      mdmStatusSummary += `last seen on ${lastSeenAtStr}`;
    }
    if (!this._mdmStatus.lastSeenAt && this._mdmStatus.isOnline === false) {
      mdmStatusSummary += `Offline in MDM at least as of as of ${TimeHelper.format(Date.now())}`;
    }

    if (this._mdmStatus.group?.name) {
      mdmStatusSummary += `MDM Group: ${this._mdmStatus.group.name}; `;
    }

    return mdmStatusSummary;
  },

  fetchMdmStatus() {
    if (!this._canFetchMdmStatus()) {
      return;
    }

    // Refetch mdm status if saved data is using SimpleMDM device status format
    if (
      this._didFetchMdmStatus &&
      this._mdmStatus &&
      !this._mdmStatus.attributes &&
      !this._mdmStatus.group?.attributes
    ) {
      return;
    }

    const self = this;
    app.makeRequestWithOptions({
      url: `${this.url()}/mdm-status`,
      // Specify the orgId and locationId headers because this endpoint might be called from the
      // bite-level page where no org is defined.
      headers: {
        [ApiHeader.OrgId]: this.get('orgId'),
        [ApiHeader.LocationId]: this.get('locationId'),
      },
      showAlertOnError: false,
      onSuccess(data) {
        self._didFetchMdmStatus = true;
        self._mdmStatus = data.mdmStatus;
        self.trigger('change');
      },
      onFailure(/* err */) {
        self._didFetchMdmStatus = true;
        self.trigger('change');
      },
    });
  },

  _canFetchMdmStatus() {
    if (!KioskDeviceTypeHelper.mdmSupportsFetchingStatus(this.get('deviceType'))) {
      return false;
    }

    if (this.hasKnownIssue(KioskKnownIssue.REMOVED_FROM_MDM)) {
      return false;
    }

    // Don't fetch mdm information for devices that are clearly not real
    // ChromeOS MDM devices have serial numbers >=8 as far as we've seen
    if ((this.get('serialNumber') || '').length < 8) {
      return false;
    }

    // Limit status fetching to bite users since SimpleMDM has pretty tight rate limiting
    if (this.isIOS() && !app.sessionUser.isBite()) {
      return false;
    }

    return true;
  },

  isIOS() {
    return KioskDeviceTypeHelper.isIOS(this.get('deviceType'));
  },

  _hasStatusWarningWithCode(code) {
    const deviceStatus = this.get('status')?.deviceStatus;
    const kioskDeviceType = this.get('deviceType');
    if (!deviceStatus) {
      return false;
    }

    return KioskHelper.parseDeviceStatus(deviceStatus, kioskDeviceType).warnings.some((warning) => {
      return warning.code === code;
    });
  },

  matchesQuery(query /* optDisplayName */) {
    if (!query) {
      return true;
    }

    let location;
    if (app.orgList) {
      location = app.orgList.getLocationById(this.get('locationId'));
    } else {
      location = app.locationList.get(this.get('locationId'));
    }

    const specialTokens = query
      .toLowerCase()
      .split(' ')
      .filter((token) => {
        return token.charAt(0) === ':';
      });
    const matchesAllTokens = specialTokens.every((token) => {
      switch (token) {
        // OS/MDM
        case ':android':
          return KioskDeviceTypeHelper.isAndroid(this.get('deviceType'));
        case ':chromeos':
          return KioskDeviceTypeHelper.usesChromeMdm(this.get('deviceType'));
        case ':eloview':
          return KioskDeviceTypeHelper.usesEloView(this.get('deviceType'));
        case ':esper':
          return KioskDeviceTypeHelper.usesEsper(this.get('deviceType'));
        case ':workspace-one':
          return KioskDeviceTypeHelper.usesWorkspaceOneMdm(this.get('deviceType'));
        case ':ios':
        case ':simplemdm':
          return this.isIOS();
        case ':signageos':
        case ':tizen':
          return KioskDeviceTypeHelper.usesSignageOsMdm(this.get('deviceType'));

        // Peripherals
        case ':has-printer':
          return !!this.get('printerType');
        case ':no-printer':
          return !this.get('printerType');
        case ':has-hid':
          return !!this.get('hasHid');
        case ':has-msr':
          return !!this.get('hasMsr');
        case ':has-scanner':
          return !!this.get('hasScanner');
        case ':jaws-licensed':
          return !!this.get('jawsStatus') && this.get('jawsStatus').isLicensed;
        case ':jaws-unlicensed':
          return !!this.get('jawsStatus') && !this.get('jawsStatus').isLicensed;

        // Problems
        case ':missing':
          return (
            !!this.get('status') &&
            Date.now() - (this.get('status').lastStatusReceivedAt || this.get('status').createdAt) >
              Time.DAY
          );
        case ':missing-payment':
          return (
            this.get('paymentTerminalModel') &&
            this.get('status') &&
            !this.get('status').paymentTerminalStatus
          );
        case ':missing-peripherals':
          if (!this.get('status')) {
            return false;
          }
          if (this.get('printerType') && !this.get('status').kioskPrinterStatus) {
            return true;
          }
          if (this.get('hasHid') && !this.get('status').hidStatus) {
            return true;
          }
          if (this.get('hasMsr') && !this.get('status').msrStatus) {
            return true;
          }
          if (this.get('hasScanner') && !this.get('status').scannerStatus) {
            return true;
          }
          return false;
        case ':outdated-build':
          return (
            this._hasStatusWarningWithCode(KioskHelper.KioskWarning.OutdatedBuild) &&
            // If a kiosk has not been online for 2 weeks, we don't care about it
            this.get('status').createdAt > Date.now() - 2 * Time.WEEK
          );
        case ':outdated-os':
          return this._hasStatusWarningWithCode(KioskHelper.KioskWarning.OutdatedOs);
        case ':outdated-webview':
          return this._hasStatusWarningWithCode(KioskHelper.KioskWarning.OutdatedWebView);
        case ':pusher-disconnected':
          if (!this.get('status')) {
            return false;
          }
          return this.get('status').pusherState !== KioskPusherState.Subscribed;
        case ':unhandled-model':
          return this._hasStatusWarningWithCode(KioskHelper.KioskWarning.UnhandledModel);
        case ':wrong-dpi':
          return this._hasStatusWarningWithCode(KioskHelper.KioskWarning.WrongDpi);
        default:
          return location.matchesQuery(token);
      }
    });
    if (!matchesAllTokens) {
      return false;
    }

    const tokenlessQuery = query
      .split(' ')
      .filter((token) => {
        return token.charAt(0) !== ':';
      })
      .join(' ');
    return location.matchesQuery(tokenlessQuery);
  },

  _kioskLocationHasIntegrationWithSystem(system) {
    const location = app.orgList.getLocationById(this.get('locationId'));
    return location.hasIntegrationWithSystem(system);
  },

  updatePaymentTerminalIdleText() {
    app.makeRequestWithOptions({
      method: 'PUT',
      url: `${this.url()}/payment-terminals/idle-text`,
      // Specify the orgId and locationId headers because this endpoint might be called from the
      // bite-level page where no org is defined.
      headers: {
        [ApiHeader.OrgId]: this.get('orgId'),
        [ApiHeader.LocationId]: this.get('locationId'),
        [ApiHeader.KioskId]: this.id,
      },
      onSuccess(data) {
        app.showSavedToast(
          `Updated the kiosks' payment terminal idle text to ${data.idleText === '' ? '(default)' : data.idleText}`,
        );
      },
    });
  },

  restartDeviceThroughMDM() {
    app.makeRequestWithOptions({
      method: 'POST',
      url: `${this.url()}/mdm-restart`,
      // Specify the orgId and locationId headers because this endpoint might be called from the
      // bite-level page where no org is defined.
      headers: {
        [ApiHeader.OrgId]: this.get('orgId'),
        [ApiHeader.LocationId]: this.get('locationId'),
      },
      onSuccess() {
        app.showSavedToast('Restart command sent!');
      },
    });
  },

  notifyDevice(notificationType, data, options) {
    app.makeRequestWithOptions({
      method: 'POST',
      data,
      url: `${this.url()}/notify?type=${notificationType}`,
      // Specify the orgId and locationId headers because this endpoint might be called from the
      // bite-level page where no org is defined.
      headers: {
        [ApiHeader.OrgId]: this.get('orgId'),
        [ApiHeader.LocationId]: this.get('locationId'),
      },
      onSuccess: options.success,
      onFailure: options.error,
    });
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'deviceType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: KioskDeviceType,
          nameGenerator: KioskDeviceTypeHelper.name,
        });
      case 'paymentTerminalModel':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: KioskPaymentTerminalModel,
          nameGenerator: KioskPaymentTerminalModelHelper.name,
        });
      case 'peripheralType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: KioskPeripheralType,
          nameGenerator: KioskPeripheralTypeHelper.name,
          values: allEnumValues(KioskPeripheralType).filter((peripheralType) => {
            return KioskPeripheralTypeHelper.isSupported(peripheralType);
          }),
        });
      case 'printerType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: PrinterType,
          nameGenerator: PrinterTypeHelper.name,
        });
      case 'knownIssues':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: KioskKnownIssue,
          nameGenerator: app.Kiosk.nameFromKnownIssue,
        });
    }
    return null;
  },

  url() {
    return `/api/v2/kiosks/${this.id ?? ''}`;
  },

  moveDeviceToMDMGroupASAM(moveToASAM) {
    app.makeRequestWithOptions({
      url: `${this.url()}/${moveToASAM ? 'asam' : 'sam'}`,
      method: 'POST',
      // Specify the orgId and locationId headers because this endpoint might be called from the
      // bite-level page where no org is defined.
      headers: {
        [ApiHeader.OrgId]: this.get('orgId'),
        [ApiHeader.LocationId]: this.get('locationId'),
      },
      onSuccess() {
        app.showSavedToast('Success!');
      },
    });
  },
});

app.Kiosk.defaultNameFromLastKiosk = function defaultNameFromLastKiosk(lastKiosk) {
  if (!lastKiosk) {
    return null;
  }

  const trailingNumberRegex = /[0-9]+$/;
  let match = lastKiosk.get('name').match(trailingNumberRegex);
  if (match) {
    // Increment the number at the end (e.g. if it was DT1, return DT2)
    const nextNumber = parseInt(match[0], 10) + 1;
    return lastKiosk.get('name').replace(trailingNumberRegex, nextNumber);
  }

  const trailingLetterRegex = /\b[a-z]+$/i;
  match = lastKiosk.get('name').match(trailingLetterRegex);
  if (match) {
    const nextLetter = String.fromCharCode(match[0].charCodeAt(0) + 1);
    // Return the next letter (e.g. if it was C, return D)
    return lastKiosk.get('name').replace(trailingLetterRegex, nextLetter);
  }

  return null;
};

app.Kiosk.nameFromKnownIssue = (knownIssue) => {
  switch (knownIssue) {
    case KioskKnownIssue.IN_TRANSIT:
      return 'In Transit';
    case KioskKnownIssue.FAULTY_CHARGER:
      return 'Faulty Charger';
    case KioskKnownIssue.AWOL:
      return 'Device is AWOL';
    case KioskKnownIssue.DEMO_DEVICE:
      return 'Demo Device: not expected to be on all the time';
    case KioskKnownIssue.REMOVED_FROM_MDM:
      return 'Removed from MDM';
    case KioskKnownIssue.TEMPORARILY_UNUSED:
      return 'Temporarily Unused';
  }
  return null;
};
