import async from 'async';
import Fuse from 'fuse.js';
import _ from 'underscore';

import { ItemTaxationStatus } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';

import { template } from '../../template';

app.MenuItemDetailsView = app.BaseDetailsView.extend({
  initialize() {
    app.BaseDetailsView.prototype.initialize.apply(this, arguments);

    if (!app.badgeList.hasBeenFetched()) {
      this.listenToOnce(app.badgeList, 'reset', () => {
        this.render();
      });
      app.badgeList.fetch({ reset: true });
    }

    if (app.location.usesTaxProfiles() && !app.taxProfileList.hasBeenFetched()) {
      this.listenToOnce(app.taxProfileList, 'reset', () => {
        this.render();
      });
      app.taxProfileList.fetch({ reset: true });
    }
  },

  getTitle() {
    let title = app.BaseDetailsView.prototype.getTitle.apply(this);
    if (this.model.get('variationSource')) {
      title += ' Variation';
    }
    if (this.options.array && this.options.array.displayName()) {
      title += ` in ${this.options.array.displayName()}`;
    }
    return title;
  },

  _updatePriceOptionCategoryField() {
    const fieldViews = this.fieldGroupView.fieldViewsByField;
    if (fieldViews.priceOptions && fieldViews.priceOptionCategory) {
      const priceOptions = fieldViews.priceOptions.getValue();
      const show = _.size(priceOptions) >= 2;
      fieldViews.priceOptionCategory.$el.toggle(show);
    }
  },

  _updateTaxProfileIdField() {
    const fieldViews = this.fieldGroupView.fieldViewsByField;
    if (fieldViews.taxationStatus && fieldViews.taxProfileId) {
      const status = fieldViews.taxationStatus.getValue();
      const show =
        status === ItemTaxationStatus.Taxable || status === ItemTaxationStatus.TaxIncluded;
      fieldViews.taxProfileId.$el.toggle(show);
    }
  },

  _updateI9nSpecificFields() {
    const fieldViews = this.fieldGroupView.fieldViewsByField;
    if (fieldViews.denormalizeModGroups && !this.model.supportsDenormalizingModGroups()) {
      fieldViews.denormalizeModGroups.$el.toggle(false);
    }
    if (fieldViews.usesModCodes && !this.model.supportsUsingModCodes()) {
      fieldViews.usesModCodes.$el.toggle(false);
    }
    if (fieldViews.syncMultiplePriceOptions && !this.model.supportsSyncingMultiplePriceOptions()) {
      fieldViews.syncMultiplePriceOptions.$el.toggle(false);
    }
  },

  __fieldGroupDidChangeValue() {
    app.BaseDetailsView.prototype.__fieldGroupDidChangeValue.apply(this, arguments);

    this._updatePriceOptionCategoryField();
    this._updateTaxProfileIdField();
    this._updateI9nSpecificFields();
  },

  _removeItemFromAllSections($button) {
    const reqId = $button.initLoadingButton($button.html(), 'removing', 'removed');

    const itemId = this.model.id;
    const collection = this.model.isMod() ? app.modGroupList : app.menuSectionList;
    const sections = collection.filter((itemArray) => {
      return itemArray.hasItemWithId(itemId);
    });

    const self = this;
    async.forEachSeries(
      sections,
      (section, cb) => {
        section.removeItem(
          itemId,
          () => {
            cb(null);
          },
          () => {
            cb('error');
          },
        );
      },
      (err) => {
        if (err) {
          $button.loadingDidFinishWithError(reqId);
        } else {
          $button.loadingDidFinishSuccessfully(reqId);
          app.showSavedToast('Item Removed!');
        }
        self.render();
        self.model.trigger('change');
      },
    );
  },

  _save(data) {
    const name = data.name || this.model.get('name');
    if (this.model.isNew() && name) {
      // First, do a tokenized search to get a broader range of possibilities
      const fuse = new Fuse(this.model.collection.models, {
        keys: ['name'],
        threshold: 0.2,
        tokenize: true,
        getFn(obj, path) {
          return obj.get(path);
        },
      });
      const internalName = data.internalName ?? this.model.get('internalName');
      let results = fuse.search(name);

      // Filter out matches that have a different spiritId. That way we don't
      // bother with a "Crown Royal XO" vs "Crown Royal".
      if (data.spiritId) {
        results = _.filter(results, (item) => {
          return !item.hasStr('spiritId') || item.get('spiritId') === data.spiritId;
        });
      }

      // Then, try to match each of the results one by one without tokenizing.
      // However, always match a shorter string against a longer one.
      // That allows us to detect that "Ken Falls Double Awkward" could be a
      // dupe of "Doubly Awkward".
      const matches = [];
      _.each(results, (result) => {
        const { item } = result;
        let searchTerm = name;
        let searchSet = [item];
        if (item.get('name').length < name.length) {
          searchTerm = RegExp.escape(item.get('name'));
          searchSet = [new app.MenuItem({ name })];
        }
        const fuse2 = new Fuse(searchSet, {
          keys: ['name'],
          threshold: 0.2,
          getFn(obj, path) {
            return obj.get(path);
          },
        });
        if (fuse2.search(searchTerm).length) {
          matches.push(item);
        }
      });

      const exactMatch = matches.find((item) => {
        const futureMagicCopyKey = StringHelper.slugArray(name, internalName);

        return item.get('magicCopyKey') === futureMagicCopyKey;
      });

      // If we do not have a pos we cannot have duplicate items
      // This is handled in the base list view
      if (matches.length && (app.location.getPosI9nSchema() || !exactMatch)) {
        const args = arguments;
        let warning = 'Oops! An item with a similar name already exists.';
        let confirmationText = 'Create a duplicate?';

        app.AlertView.showModelList(matches, warning, confirmationText, () => {
          app.BaseDetailsView.prototype._save.apply(this, args);
        });
        return;
      }
    }
    app.BaseDetailsView.prototype._save.apply(this, arguments);
  },

  _renderPosIdGroup() {
    if (!this.model.isSynced()) {
      return;
    }

    const $displayGroup = $('<div class="field-group"></div>');
    $displayGroup.append(
      this.valueDisplayTemplate({
        label: 'POS IDs:',
        value: this.model.getPosIdSummary(),
      }),
    );
    this.$el.prepend($displayGroup);
  },

  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    this.listenTo(
      this.fieldGroupView.fieldViewsByField.priceOptions,
      app.AccordionFieldView.Events.AddedNewFieldGroup,
      this._updatePriceOptionCategoryField,
    );
    this._updatePriceOptionCategoryField();
    this.listenTo(
      this.fieldGroupView.fieldViewsByField.taxationStatus,
      app.FieldView.Events.FieldDidChangeValue,
      this._updateTaxProfileIdField,
    );
    this._updateTaxProfileIdField();
    this._updateI9nSpecificFields();

    if (!this.model.isNew() && app.location) {
      const withTemplate = template($('#visible-in-template').html());

      const visibleInPlaces = this.model.getVisibleInPlaces();
      const $visibleInSection = $(
        withTemplate({
          labelText: 'Visible in:',
          visibleInHtml: app.HtmlHelper.visibleInHtml(visibleInPlaces),
          canBeRemoved: this.model.canChangeParent() && visibleInPlaces.length,
          removeTitle: 'remove from\nall sections',
          canBeAdded: this.model.canChangeParent() && !visibleInPlaces.length,
          addTitle: 'Section',
        }),
      );

      const $removeButton = $visibleInSection.find('button.remove');

      if (app.sessionUser.canManageMenu()) {
        $removeButton.click(() => {
          this._removeItemFromAllSections($removeButton);
          return false;
        });
      } else {
        $removeButton.prop('disabled', true);
      }

      const $collapsedDropdown = $visibleInSection.find('.dropdown-menu.collapsed');
      $collapsedDropdown.find('.item-remove').click(() => {
        this._removeItemFromAllSections($removeButton);
        return false;
      });

      // Set up Add to Section dropdown menu.
      if (!visibleInPlaces.length) {
        const $moveButton = $visibleInSection.find('.move-item');
        $moveButton.prop('disabled', !app.sessionUser.canManageMenu());
        const allSections = app.menuSectionList.models;
        const $moveDropdown = $visibleInSection.find('.move-menu');
        _.each(allSections, (section) => {
          const $section = $(`<li class="item section-item"><a>${section.displayName()}</a></li>`);
          $section.click(() => {
            section.addItem(this.model.id, () => {
              app.showSavedToast('Added to the end of the list!');
              this.render();
              this.model.trigger('change');
            });
          });
          $moveDropdown.append($section);
        });
      }

      const fieldViews = this.fieldGroupView.fieldViewsByField;
      let fieldView = fieldViews.story || fieldViews.description;
      const hasPosDescriptionAfter =
        fieldView.field === 'description' && this.getSchema().fields.posDescription;
      if (
        this.getSchema().fields[fieldView.field].canHaveLocalizationOverrides &&
        app.locationSettings.get('supportedLanguages').length > 1 &&
        !hasPosDescriptionAfter
      ) {
        const language = _.last(app.locationSettings.get('supportedLanguages'));
        fieldView = fieldViews[`${fieldView.field}_${language}`];
      } else if (hasPosDescriptionAfter) {
        fieldView = fieldViews.posDescription;
      }
      // Fallback to the description field in case the expected description field does not exist.
      fieldView = fieldView ?? fieldViews.description;
      $visibleInSection.insertAfter(fieldView.$el);
    }

    if (app.sessionUser.isBite() && this.model.get('spiritId') && app.location) {
      const spiritId = this.model.get('spiritId');
      const $cellarSection = $(
        '<div class="field-container from-spiritId">' +
          '<label class="col-form-label col-md-4">Comes From:</label>' +
          '<div class="col-md-8 input-container">' +
          `<a model-id="${this.model.get('spiritId')}">${this.model.get('spiritId')}</a>` +
          '<span class="fetching form-control-feedback"></span>' +
          '</div>' +
          '</div>',
      );
      $cellarSection.find('a').click(() => {
        $cellarSection.find('.fetching').show();
        const spirit = new app.Spirit({ _id: spiritId });
        spirit.fetch({
          error() {
            $cellarSection.find('.fetching').hide();
          },
          success(model) {
            $cellarSection.find('.fetching').hide();
            const detailsView = new app.SpiritDetailsView({
              model,
              schema: app.Spirit.Schemas[model.get('category')],
            });
            app.modalManager.showModalWithView(detailsView);
          },
        });
      });

      const afterFieldView =
        this.fieldGroupView.fieldViewsByField.story || this.fieldGroupView.fieldViewsByField.name;
      $cellarSection.insertAfter(afterFieldView.$el);
    }

    this._renderPosIdGroup();

    return this;
  },
});
